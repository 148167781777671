import anime from 'animejs';
import LocomotiveScroll from 'locomotive-scroll'
import { threeRunner } from './hero';

window.addEventListener('DOMContentLoaded', (e) => {
    
    const logo = document.querySelector('#logo');
    logo.addEventListener('click', (e)=>{
        location.assign('./index.html')
    })
    const links = document.querySelectorAll('a');

    const cursor = document.querySelector('.cursor');
    let mx, my;
    links.forEach(link => {
        link.addEventListener('mouseenter', (e) => {
            cursor.style.mixBlendMode = 'difference';
            anime({
                targets: cursor,
                width: 80,
                height: 80,
                top: -20,
                left: -20,
                backgroundColor: `#ffffff`,
                easing: 'easeInOutQuad',
                duration: 100
            })
        });
        link.addEventListener('mouseleave', (e) => {
            cursor.style.mixBlendMode = 'normal';
            cursor.style.backgroundColor = '#ffffff00';
            anime({
                targets: cursor,
                width: 40,
                height: 40,
                top: 0,
                left: 0,
                backgroundColor: `#ffffff00`,
                easing: 'easeInOutQuad',
                duration: 100
            })
        })
    })
    
    window.addEventListener('mousemove', function(e){
        mx = e.clientX - 20;
        my = e.clientY - 20;
        cursor.style.transform = `translate(${mx}px, ${my}px)`;
        // cursor.style.transform = `translateY`;
    })
    
    const menuIcon = document.querySelector('#nav');
    const menu = document.querySelector('#menu-wrapper');
    const item = document.querySelector('.item-wrapper');
    const itemHeight = item.getBoundingClientRect().height;
    
    menuIcon.addEventListener('click', (e) => {
        menuIcon.classList.toggle('active');
        if(menuIcon.classList.contains('active')){
            openMenu();
            anime({
                targets: '.item-wrapper',
                height: 0,
                duration: 500,
                delay: anime.stagger(200),
                easing: 'easeInOutSine'
            });
            
            anime({
                targets: menu,
                translateY: [-window.innerHeight, 0],
                opacity: [0, 1],
                duration: 500,
                easing: 'easeInOutSine'
            });
        }
        
        if(!menuIcon.classList.contains('active')){
            closeMenu();
            anime({
                targets: '.item-wrapper',
                height: itemHeight,
                duration: 500,
                delay: anime.stagger(100),
                easing: 'easeInOutSine'
            });
            
            anime({
                targets: menu,
                translateY: -window.innerHeight*2,
                opacity: 0,
                delay: 1400,
                duration: 400,
                easing: 'easeInOutSine'
            });
        }
        
    });
    
    const openMenu = () => {
        const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
        const body = document.body;
        body.style.position = 'fixed';
        body.style.top = `-${scrollY}`;
    };
    
    const closeMenu = () => {
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
    
    window.addEventListener('scroll', () => {
        document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });
    
      
    const canvas = document.querySelector('canvas');
    if(canvas){
        threeRunner();
    }
    else{
        const scroll = new LocomotiveScroll({
            el: document.querySelector('[data-scroll-container]'),
            smooth: true,
            smoothMobile: false
        });
        let tlLoad = anime.timeline({
            easing: 'easeInOutQuart',
            duration: 1400
        })
        .add({
            targets: '#nav img',
            translateY: [-100, 0],
            opacity: [0, 1]
        })
        .add({
            targets: '#logo',
            translateY: [-100, 0],
            opacity: [0, 1]
        }, '-=1200')        
    }
})
