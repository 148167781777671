import * as THREE from 'three/build/three.module';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import anime from 'animejs';
import Lottie from 'lottie-web';
import LocomotiveScroll from 'locomotive-scroll';

export let trackList;
export function threeRunner(){
    let scene, camera, renderer, man, modelsLoader, dracoLoader;
    const canvas = document.querySelector('.hero');
    const bgColor = new THREE.Color(0x0f0f0f);
    scene = new THREE.Scene();
    
    camera = new THREE.PerspectiveCamera(45, window.innerWidth/window.innerHeight, 0.1, 1000);
    scene.add(camera);
    camera.lookAt( 0, 0, 0 );

    renderer = new THREE.WebGLRenderer({canvas});
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.toneMapping = THREE.ReinhardToneMapping;
    
    let BLOOM_SCENE = 1;

	let bloomLayer = new THREE.Layers();
	bloomLayer.set( BLOOM_SCENE );

	let params = {
		exposure: 1.3,
		bloomStrength: 1.5,
		bloomThreshold: 0,
		bloomRadius: 0,
	};

	let darkMaterial = new THREE.MeshBasicMaterial( { color: "black" } );
	let materials = {};

    renderer.toneMappingExposure = Math.pow( 1.3, 4.0 );

    let renderScene = new RenderPass( scene, camera );

	let bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
	bloomPass.threshold = params.bloomThreshold;
	bloomPass.strength = params.bloomStrength;
	bloomPass.radius = params.bloomRadius;

	let bloomComposer = new EffectComposer( renderer );
	bloomComposer.renderToScreen = false;
	bloomComposer.addPass( renderScene );
	bloomComposer.addPass( bloomPass );

	let finalPass = new ShaderPass(
		new THREE.ShaderMaterial( {
			uniforms: {
				baseTexture: { value: null },
				bloomTexture: { value: bloomComposer.renderTarget2.texture }
			},
			vertexShader: document.getElementById( 'vertexshader' ).textContent,
			fragmentShader: document.getElementById( 'fragmentshader' ).textContent,
			defines: {}
		} ), "baseTexture"
	);
	finalPass.needsSwap = true;
    
    let fxaaPass = new ShaderPass( FXAAShader );
    let pixelRatio = renderer.getPixelRatio();
    fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( window.innerWidth * pixelRatio);
    fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / ( window.innerHeight * pixelRatio);


	let finalComposer = new EffectComposer( renderer );
	finalComposer.addPass( renderScene );
    finalComposer.addPass( finalPass );
    finalComposer.addPass( fxaaPass );
    
    bloomComposer.renderToScreen = false;


    window.addEventListener("resize",function()
    {
        let width = window.innerWidth;
        let height = window.innerHeight;
        renderer.setSize(width, height);
        camera.aspect = width/height;
        camera.updateProjectionMatrix();
        let pixelRatio = renderer.getPixelRatio();
        fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( width * pixelRatio );
        fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / ( height * pixelRatio );

        bloomComposer.setSize( width, height );
		finalComposer.setSize( width, height );

		render();
    });



    const ambientLight = new THREE.AmbientLight(0xffffff,0.05);
    scene.add(ambientLight);

    const dLight = new THREE.DirectionalLight(0xffffff, 0.4);
    scene.add( dLight );
    dLight.position.set(3, 0.66, -3)
    dLight.castShadow = true;

    const pLight = new THREE.PointLight(0x905dce, 0.7, 100);
    scene.add( pLight );
    pLight.position.set(0.21, -0.5, 0.72);
    pLight.castShadow = true;

    const pLight2 = new THREE.PointLight(0x905dce, 0.3, 100);
    scene.add( pLight2 );
    pLight2.position.set(1, 0.2, 1);
    pLight2.castShadow = true;

    let scrollTop, scrollLeft;
    function disableScroll() { 
        // Get the current page scroll position 
        scrollTop = window.pageYOffset || document.documentElement.scrollTop; 
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft, 
      
            // if any scroll is attempted, set this to the previous value 
            window.onscroll = function() { 
                window.scrollTo(0, 0); 
            }; 

            window.addEventListener('wheel', function() {
                window.scrollTo(0, 0); 
            })
    } 
      
    function enableScroll() { 
        window.onscroll = function() {}; 
    } 

    const bar = document.querySelector('.loading-bar');
    const enterButton = document.querySelector('.enter');

    
    let loadingScreen ;
    const loadingManager = new THREE.LoadingManager();

    
    loadingManager.onStart = function() {
        loadingScreen = document.querySelector( '.loader-wrapper' );
        disableScroll();
    };

    loadingManager.onLoad = async function () {
        enterButton.style.opacity = 1;

        enterButton.addEventListener('click', (e) => {enterNow(e)})

            
        function enterNow(e){   
            const scroll = new LocomotiveScroll({
                el: document.querySelector('[data-scroll-container]'),
                smooth: true,
                smoothMobile: false
            });
            enableScroll();             
            e.preventDefault(); 
            enterButton.style['pointer-events'] = 'none';
            enterButton.style.opacity = 0;
            loadingScreen.classList.add( 'fade-out' );
            playAudio();
            introAnimation();
            
        }
        enterButton.removeEventListener('click', enterNow);
            async function introAnimation(){
                let tlLoad = anime.timeline({
                    easing: 'easeInOutQuart',
                    duration: 2000
                })
                .add({
                    targets: '.loading-bar',
                    opacity: [1, 0]
                },)
                .add({
                    targets: '.hrsh-intro h3',
                    opacity: [1, 0]
                }, '-=1500')
                .add({
                    targets: loadingScreen,
                    opacity: 0
                }, '-=1000')
                .add({
                    targets: '#nav img',
                    translateY: [-100, 0],
                    opacity: [0, 1]
                }, '-=1200')
                .add({
                    targets: '.sound-wrapper',
                    translateY: [-100, 0],
                    opacity: [0, 1]
                }, '-=1500')
                .add({
                    targets: '#logo',
                    translateY: [-100, 0],
                    opacity: [0, 1]
                }, '-=1400')
                
                .add({
                    targets: '.hrsh-title',
                    translateY: '44vh'
                }, '-=3000')
                

            
                playAudio();
            }
    };
    
    loadingManager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
        let percent = (itemsLoaded/itemsTotal) * 100;
        bar.style.width = `${percent}%`;
    };
    
    loadingManager.onError = function(url) {
        console.log( 'There was an error loading ' + url );
    };

   
    man = new THREE.Mesh();
    let loader = new GLTFLoader(loadingManager);
    
    let wmaterial = new THREE.MeshBasicMaterial({
        color: 0x0a40d0,
    });

    
    loader.load('/assets/fullmodel_UNCOMP.glb', (glb) => {
        const box = new THREE.Box3().setFromObject( glb.scene );
        const center = box.getCenter( new THREE.Vector3() );
        
        glb.scene.position.x += ( glb.scene.position.x - center.x );
        glb.scene.position.y += ( glb.scene.position.y - center.y );
        glb.scene.position.z += ( glb.scene.position.z - center.z );

        man.add(glb.scene);
        scene.add(man);
        man.position.set(0, -1.3, 0);
        man.scale.set(1, 1, 1);
        man.traverse((child)=>{
            if(child.isMesh){
                child.receiveShadow = true;
                child.castShadow = true;
                if(child.name == 'left_eye' || child.name == 'right_eye'){
                    child.material = wmaterial
                    child.layers.enable( BLOOM_SCENE );
                }
            }
        });
        man.receiveShadow = true;
        man.castShadow = true;
        
    });
    
    let cage = new THREE.Mesh();
    loader.load('/assets/cage.gltf', (gltf) => {
        const box = new THREE.Box3().setFromObject( gltf.scene );
        const center = box.getCenter( new THREE.Vector3() );
        
        gltf.scene.position.x += ( gltf.scene.position.x - center.x );
        gltf.scene.position.y += ( gltf.scene.position.y - center.y );
        gltf.scene.position.z += ( gltf.scene.position.z - center.z );
       
        cage.add(gltf.scene);
        cage.traverse((o) => {
            o.material = wmaterial;
            o.layers.enable( BLOOM_SCENE );

        })
        scene.add(cage);
        cage.position.set(-0.3, -0.1, 3);
        cage.scale.set(1, 1, 1);
        
        render();
    });
    
    camera.position.set(0, 0, 4)
    function darkenNonBloomed( obj ) {

        if ( obj.isMesh && bloomLayer.test( obj.layers ) === false ) {

            materials[ obj.uuid ] = obj.material;
            obj.material = darkMaterial;

        }

    }

    function restoreMaterial( obj ) {

        if ( materials[ obj.uuid ] ) {

            obj.material = materials[ obj.uuid ];
            delete materials[ obj.uuid ];

        }

    }
    const hfToken = document.querySelector('#hidden_token');
    let trackPreviewURL = 0;
    let trackSpotifyURL = 0;
    let trackName = 0;
    const app ={}
    app.tokenInfo = {};
    const url = new URL(location.href);
    app.tokenInfo = {
      access_token: url.searchParams.get("access_token")
    };    
        
    
    app.getToken = async () => {
        const tokenResult = await fetch('https://hrsh-proxy.herokuapp.com/guestrefresh');
        const { access_token } = await tokenResult.json();
        // resolve(access_token);
        return access_token;
        
      };

    const hrshId = '4UjY1yWEYSBxyTNwZzCJB9';
    var token;
    const getTrackData = async () => {
        const name = document.querySelector('.song-name');        
        token = await app.getToken();
        hfToken.value = token;
        
        const trackResult = await fetch(`https://api.spotify.com/v1/artists/${hrshId}/top-tracks?market=IN&limit=10`, {
            method: 'GET',
            headers: { 
                'Accept': 'application/json',
                'Content-Type' : 'application/json', 
                'Authorization' : 'Bearer ' + hfToken.value
            }
        });

        const trackData = await trackResult.json();  
        trackPreviewURL = await trackData.tracks[0].preview_url;
        trackSpotifyURL = await trackData.tracks[0].external_urls.spotify;
        trackName = await trackData.tracks[0].name;
        name.innerText = trackName;
        document.querySelector('#track').src = trackPreviewURL;
        document.querySelector('.song-link').href = trackSpotifyURL;
    };
    getTrackData();
    
    
    const audioElement = document.querySelector('#track');
    audioElement.crossOrigin = 'anonymous';   


        const soundBox = document.querySelector('.sound');

        let animationIcon = Lottie.loadAnimation({
            container: soundBox,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            path: './assets/soundicon.json',
        });

        
        let analyzer, bufferLength;
        let animeCall;
        let percent;
        const audioCtx = new AudioContext();
        const track = audioCtx.createMediaElementSource(audioElement);
        audioCtx.resume();
        
        let gainNode = audioCtx.createGain();

        gainNode.gain.setValueAtTime(0.0001, audioCtx.currentTime); 
        gainNode.gain.exponentialRampToValueAtTime(0.01, audioCtx.currentTime);
        gainNode.gain.exponentialRampToValueAtTime(1.0, audioCtx.currentTime + 2);
        analyzer = audioCtx.createAnalyser()
        
        track.connect(gainNode).connect(analyzer).connect(audioCtx.destination);
    
        // How much data we want to collect
        analyzer.fftSize = 2 ** 10;
        bufferLength = analyzer.frequencyBinCount; 
        const timeData = new Uint8Array(bufferLength); 
        
        async function playAudio()
        {if (audioCtx.state === 'suspended') {
            audioCtx.resume();
        }
            await audioElement.play();
            animationIcon.play();
            getTimeData(timeData);
            audioElement.addEventListener('ended', function(){
                audioElement.play();
                animationIcon.play();
            })
        }

        soundBox.addEventListener('click', function(){
            
            if(audioElement.paused){
                audioElement.play();
                animationIcon.play();
                getTimeData(timeData);
                
            }else if(!audioElement.paused){
                audioElement.pause();
                animationIcon.pause();
                cancelAnimationFrame(animeCall)
            }

            if (audioCtx.state === 'suspended') {
                audioCtx.resume();
            }
            audioElement.addEventListener('ended', function(){
                audioElement.play();
                animationIcon.play();
            })
            
        }, false);

        
                
        function getTimeData(timeData) {
            analyzer.getByteTimeDomainData(timeData);
            //calculate colors
            const timeStamp = timeData[0];
            const percent = timeStamp / 255;
            const [h, s, l] = [Math.max(0.6, percent*10), percent/2, percent/2];
            cage.material.color.setHSL(h, s, l);
            bloomPass.strength = percent*5;
            animeCall = requestAnimationFrame(() => getTimeData(timeData));
        }        
        let lerp = function(a, b, f)
        {
            return a + f * (b - a);
        }   
        let scrollY = 0;
        document.addEventListener("wheel", function(e){
            
            scrollY += e.deltaY;
            if(scrollY > 120 && scrollY <= window.innerHeight*3){
                var t = scrollY/(window.innerHeight/10);
                camera.position.z = lerp(4, 4.2, t);
                camera.updateProjectionMatrix();
            }            
        }, false);
       

    let mouse = new THREE.Vector2();
    function handleMouse(event){
        mouse.x = ( event.clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

        man.rotation.y = (mouse.x/2);
    }
    let scrollControl;    
    function handleTouch(event){
        mouse.x = ( event.touches[0].clientX / window.innerWidth ) * 2 - 1;
        mouse.y = - ( event.touches[0].clientY / window.innerHeight ) * 2 + 1;

        man.rotation.y = (mouse.x/2);
        camera.position.z = THREE.Math.clamp(window.scrollY/100, 4, 15);        
    
    }
    window.addEventListener('mousemove', (e) => {handleMouse(e)}, false);
    window.addEventListener("touchmove", (e) => {handleTouch(e)}, false);
    let update = function(){
        cage.rotation.y += 0.003;
        cage.rotation.z += 0.002;
        cage.rotation.x -= 0.005;
    }
    
    function render(){

        scene.traverse( darkenNonBloomed );
        bloomComposer.render();
        scene.traverse( restoreMaterial );
		finalComposer.render();
    };


    let animate = function(){
        update();
        render();
        requestAnimationFrame(animate);
    }
    animate();

}
